.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.25);

    width: 100%;
    height: 100%;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 90%;
    max-width: 500px;
    background-color: var(--background-alt);
}

.overlay-card > h3 {
    align-self: center;
}

.overlay-card > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

.overlay-card > input:focus {
    border: 1px solid var(--highlight);
}

.overlay-card > label {
    align-self: center;
    color: var(--primary-variant);
    padding: 3px;
    padding-left: 5px;
    font-size: 0.9rem;
}

.overlay-btn{
    background-color: var(--primary);
}

#score {
    border-radius: 5px;
    text-align: center;
    font-size: 5rem;
    align-self: center;
    width: 60%;
    min-height: 100px;
    max-width: 300px;
}