.add-container{
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.452);
    z-index: 1000;
}

.add-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;

    z-index: 1000;
}

.add-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
}

.add-close:hover{
    color: var(--primary);
}

.add-card > h4 {
    align-self: center;
    color: var(--primary);
}

.add-card > label {
    color: var(--primary-variant);
    padding: 5px;
}

.add-card > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

.add-card > input:focus {
    border: 1px solid var(--highlight);
}

.add-button {
    margin-top: 10px;
    align-self: center;
}

.add-radio-group > h4 {
    color: var(--primary-variant);
    padding-left: 5px;
}

.add-radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    padding: 5px;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

.radio-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--primary-variant);
    cursor: pointer;
}

.selected {
    width: 60%;
    height: 60%;
    margin: 1px;
    border-radius: 50%;
    background-color: var(--primary);
}

.add-error {
    padding-left: 5px;
}

.add-error > p {
    color: var(--error);
}