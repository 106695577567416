.signup-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    justify-content: center;
    color: var(--text);
    background-color: var(--background);
}

.signup-card {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.signup-card-header, .signup-card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-card-header > h3 {
    color: var(--primary);
}

.signup-card-main {
    display: flex;
    flex-direction: column;
}

.signup-card-main > label {
    color: var(--primary-variant);
    padding: 5px;
}

.signup-card-main > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

 .signup-button {
    align-self: center;
}

.signup-card-main > input:focus {
    border: 1px solid var(--highlight);
}

.signup-link:hover {
    color: var(--highlight);
    cursor: pointer;
}

.signup-error {
    color: var(--error);
}

@media only screen and (max-width: 600px) {
    .signup-card {
        width: 90%;
    }
  }