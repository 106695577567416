@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
body{

  --primary: #B123E0;
  --secondary: #841BF7;
  --primary-variant: #F71BEE;
  --secondary-variant: #451AED;
  --highlight: #ED1A7B;
  --background: #323232;
  --background-alt: #444444;
  --dark: #0e0e0e;
  --text: #cdcdcd;
  --error: rgb(231, 29, 29);

  --secondary-font:'Montserrat', sans-serif;
  --alt-font: 'Indie Flower', cursive;
}


html,
body, #root {
    width: 100%;
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}




.login-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    justify-content: center;
    color: var(--text);
    background-color: var(--background);
}

.login-card {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.login-card-header, .login-card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-card-header > h3 {
    color: var(--primary);
}

.login-card-main {
    display: flex;
    flex-direction: column;
}

.login-card-main > label {
    color: var(--primary-variant);
    padding: 5px;
}

.login-card-main > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

 .login-button {
    align-self: center;
}

.login-card-main > input:focus {
    border: 1px solid var(--highlight);
}

.login-link:hover {
    color: var(--highlight);
    cursor: pointer;
}

.login-error {
    color: var(--error);
}

@media only screen and (max-width: 600px) {
    .login-card {
        width: 90%;
    }
  }
.button-container {
    color: var(--text);
    font-weight: bold;
    padding: 10px;
    text-align: center;
    background-color: var(--secondary);
    cursor: pointer;
}

.button-container:hover {
    background-color: var(--secondary-variant);
}
.signup-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    justify-content: center;
    color: var(--text);
    background-color: var(--background);
}

.signup-card {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.signup-card-header, .signup-card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-card-header > h3 {
    color: var(--primary);
}

.signup-card-main {
    display: flex;
    flex-direction: column;
}

.signup-card-main > label {
    color: var(--primary-variant);
    padding: 5px;
}

.signup-card-main > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

 .signup-button {
    align-self: center;
}

.signup-card-main > input:focus {
    border: 1px solid var(--highlight);
}

.signup-link:hover {
    color: var(--highlight);
    cursor: pointer;
}

.signup-error {
    color: var(--error);
}

@media only screen and (max-width: 600px) {
    .signup-card {
        width: 90%;
    }
  }
.dash-container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 1;

    align-items: center;
    
    color: var(--text);
    background-color: var(--background);
}
.ecard-container {
    display: flex;
    width: 80%;
    height: 150px;

    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: rgb(83, 83, 83);

    cursor: pointer;
}

.ecard-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    background-color: rgb(104, 104, 104);
}

.ecard-container:hover {
    background-color: rgb(167, 167, 167);
}

.ecard-icon-container:hover {
    background-color: black;
}



.ecard-icon {
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    
  }
.add-container{
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.452);
    z-index: 1000;
}

.add-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;

    z-index: 1000;
}

.add-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
}

.add-close:hover{
    color: var(--primary);
}

.add-card > h4 {
    align-self: center;
    color: var(--primary);
}

.add-card > label {
    color: var(--primary-variant);
    padding: 5px;
}

.add-card > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

.add-card > input:focus {
    border: 1px solid var(--highlight);
}

.add-button {
    margin-top: 10px;
    align-self: center;
}

.add-radio-group > h4 {
    color: var(--primary-variant);
    padding-left: 5px;
}

.add-radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    padding: 5px;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

.radio-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--primary-variant);
    cursor: pointer;
}

.selected {
    width: 60%;
    height: 60%;
    margin: 1px;
    border-radius: 50%;
    background-color: var(--primary);
}

.add-error {
    padding-left: 5px;
}

.add-error > p {
    color: var(--error);
}
.g-container {
    display: flex;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    flex-direction: column;

    justify-content: center;
    
    
    background-color: var(--background-alt);

    cursor: pointer;
}

.g-header {
    display: flex;
    position: relative;
    flex-grow: 1;
    justify-content: center;
    padding: 3px;
    border-bottom: 1px solid var(--background);
}

.g-header > h3{
    color: var(--primary);
    margin: 0;
}

.g-header > h3:hover{
    color: var(--primary-variant);
}

.g-delete {
    font-size: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--primary-variant);
    cursor: pointer;
}

.g-delete:hover {
    color: var(--text);
}

.g-timestamp {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    font-size: 0.7rem;
    color: var(--primary-variant);
}
.fb-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 16px;
    right: 16px;

    font-size: 30px;
    background-color: var(--primary);
    padding: 5px;
    border-radius: 50%;
}

.fb-container:hover {
    background-color: var(--primary-variant);
}
.nav-container {
    position: relative;
    display: flex;
    color: var(--text);
    background-color: var(--dark);
}

.nav-container.hide {
    display: none;
}

.nav-item {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 5px;
}

.nav-item.header {
    justify-content: center;
}

.nav-item.footer {
    position: absolute;
    right: 0;
    cursor: pointer;
}

.nav-item.footer:hover {
    color: #fff;
}
.game-container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 1;

    align-items: center;
    
    color: var(--text);
    background-color: var(--background);
}

.game-card {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: var(--background-alt);
    padding: 10px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
}

.game-card-heading {
    display: flex;
    position: relative;
    align-items: center;
}

.game-card-heading > h3 {
    flex: 1 1;
    text-align: center;
    margin: 5px;
}

.game-icon {
    position: absolute;
    right: 0;
    font-size: 1.5rem;
    margin: 10px;
}

.game-card > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
    transition: all 0.2s ease-out;
}

 .add-btn {
     margin: 10px;
    align-self: center;
    transition: all 0.2s ease-out;
}

.game-card > input:focus {
    border: 1px solid var(--highlight);
}

.hide-item {
    max-height: 0;
    opacity: 0;
    line-height: 0;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
}

input.hide-item, .add-btn.hide-item, p.hide-item{
    transition: all 0.2s ease-out;
}

.game-info {
    transition: all 0.2s ease-out;
}

.game-info > p {
    transition: all 0.2s ease-out;
}
.player-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: var(--background-alt);
    padding: 10px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;

    font-family: var(--alt-font);
}

.player-name {
    display: flex;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
}

.player-name > h4 {
    flex: 1 1;
    margin: 5px;
}

.player-cs {
    margin: 5px;
    margin-top: 10px;
    font-size: 0.8rem;
    letter-spacing: 3px;
}

.player-cs > p {
    font-family: var(--secondary-font);
    margin: 5px
}

.player-rounds > h4 {
    margin: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.player-icon {
    color: var(--primary);
    padding: 5px;
    font-size: 2rem;
    border: 2px solid var(--primary);
    border-radius: 50%;
    margin-left: 15px;
}

.player-icon:hover {
    color: var(--background-alt);
    border-color: var(--background-alt);
    background-color: var(--primary);
}

.player-scores {
    display: flex;
    overflow: scroll;
}

.player-list {
    min-width: 100px;
}

.player-list > h4 {
    margin-top: 0;
    margin-bottom: 0px;
    border: 1px solid #ccc ;
    border-top: none;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
}

.player-list > p {
    font-family: var(--secondary-font);
    margin: 0;
    border: 1px solid #ccc ;
    border-top: none;
    padding: 3px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .player-list {
        min-width: 60px;
        font-size: 0.9rem;
    }
  }
.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.25);

    width: 100%;
    height: 100%;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 90%;
    max-width: 500px;
    background-color: var(--background-alt);
}

.overlay-card > h3 {
    align-self: center;
}

.overlay-card > input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--background);
    background-color: var(--background);
    color: var(--text);
}

.overlay-card > input:focus {
    border: 1px solid var(--highlight);
}

.overlay-card > label {
    align-self: center;
    color: var(--primary-variant);
    padding: 3px;
    padding-left: 5px;
    font-size: 0.9rem;
}

.overlay-btn{
    background-color: var(--primary);
}

#score {
    border-radius: 5px;
    text-align: center;
    font-size: 5rem;
    align-self: center;
    width: 60%;
    min-height: 100px;
    max-width: 300px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
