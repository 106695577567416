@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

body{

  --primary: #B123E0;
  --secondary: #841BF7;
  --primary-variant: #F71BEE;
  --secondary-variant: #451AED;
  --highlight: #ED1A7B;
  --background: #323232;
  --background-alt: #444444;
  --dark: #0e0e0e;
  --text: #cdcdcd;
  --error: rgb(231, 29, 29);

  --secondary-font:'Montserrat', sans-serif;
  --alt-font: 'Indie Flower', cursive;
}


html,
body, #root {
    width: 100%;
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}



