.nav-container {
    position: relative;
    display: flex;
    color: var(--text);
    background-color: var(--dark);
}

.nav-container.hide {
    display: none;
}

.nav-item {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 5px;
}

.nav-item.header {
    justify-content: center;
}

.nav-item.footer {
    position: absolute;
    right: 0;
    cursor: pointer;
}

.nav-item.footer:hover {
    color: #fff;
}